<template>
    <button 
        @click="downloadReport" 
        type="button"
        :disabled="isFetching || disabled">
        <slot v-if="!isFetching" name="icon"></slot>
        <Spinner v-else v-bind="spinnerAttr"></Spinner>
        <slot></slot>
    </button>
</template>

<script lang="ts" setup>
import { useMyFetch } from '@/modules/proportal/utilities';
import { useNotifications } from "@/modules/core/composables";
import { getFilenameFromContentDispositionHeader } from "@/modules/core/utilities";
import { Spinner } from '@/modules/core/components';
import { onBeforeUnmount, PropType, SVGAttributes } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import endpoints from "@/modules/proportal/endpoints";

const props = defineProps({
    consultationId: {
        type: String,
        required: true
    },
    spinnerAttr: {
        type: Object as PropType<SVGAttributes>
    },
    disabled: Boolean
})
const notifications = useNotifications();

const timezoneOffset = new Date().getTimezoneOffset();

const { data, isFetching, response, error, execute, abort, canAbort } = useMyFetch<BinaryData>(`${endpoints.CONSULTATIONS}/${props.consultationId}/final-reports/download?timezoneOffset=${timezoneOffset}`, { 
    immediate: false,
}).blob();

const downloadReport = async() => {
    notifications.showToast({
        type: 'info',
        message: 'The report is being generated. Your download will start automatically in a few moments'
    })
    await execute();

    if(response.value && response.value.ok && data.value) {
        const header = response.value.headers.get('Content-Disposition');
        const filename = getFilenameFromContentDispositionHeader(header!);
        const href = URL.createObjectURL(data.value);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = href;
        link.setAttribute('download', filename ?? 'YourReport.pdf');
        link.click();
        URL.revokeObjectURL(href);
    }

    if(error.value) {
        // No need to show this error message when user cancels the download
        if(error.value === 'The user aborted a request.') {
            return;
        }

        notifications.showToast({
            type: 'danger',
            message: error.value ?? 'Something went wrong while downloading the report. Please try again later.'
        })
    }
}

onBeforeUnmount(() => {
    // console.log(canAbort.value, 'beforeUnmount')
    if(canAbort.value) abort();
})

onBeforeRouteLeave(() => {
    // console.log(canAbort.value, 'beforeRouteLeave')
    if(canAbort.value) abort();
})

</script>

